import { MenuIcon } from "lucide-react";
import { CgProfile } from "react-icons/cg";
import { IoChevronDown } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Logo from "src/assets/logo.svg";
import { logout } from "src/redux/userSlice";
import { useAppDispatch, useAppSelector } from "src/state/hooks";

const InAppNav = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.user);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="bg-white shadow flex justify-between items-center p-4">
      <label htmlFor="my-drawer-2" className=" drawer-button lg:hidden">
        <MenuIcon />
      </label>

      <img
        src={Logo}
        alt="Logo"
        className="w-[126px] md:w-[186px] lg:w-[226px]"
        onClick={() => navigate("/")}
      />
      <div
        tabIndex={0}
        className="dropdown dropdown-hover dropdown-bottom dropdown-end flex items-center gap-1 text-sm font-medium"
      >
        <CgProfile className="size-8" />
        <span className="hidden lg:block flex-grow truncate">
         Hi {" "}
          {user?.firstname}
        </span>
        <IoChevronDown className="size-4" />

        <div
          tabIndex={0}
          className="dropdown-content cursor-pointer mt-1 menu bg-base-100  rounded-box z-[1] w-52 shadow text-black text-start flex flex-col "
        >
          {/* {[{ href: "/", label: "Poducts" }].map((item) => (
            <p
              key={item.href}
              className="h-10 flex items-center px-2 hover:pl-4 hover:bg-gray-50 duration-100"
            >
              {item.label}
            </p>
          ))} */}

          <p
            onClick={handleLogout}
            className="h-8 flex items-center justify-center text-red-500 px-2 hover:bg-gray-50 duration-100"
          >
            Log out
          </p>
        </div>
      </div>
    </div>
  );
};

export default InAppNav;
