import React, { useState } from "react";

const handleSubmit = (data: any) => {
  // Submit logic, e.g., API call to save data
  console.log("Form Submitted:", data);
};

const BusinessInfoForm = () => {
  const [formData, setFormData] = useState({
    businessName: "",
    businessEmail: "",
    address: "",
    cacDocument: null,
  });

  const handleInputChange = (field: string, value: string | File | null) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div className="bg-[#F8F8F8] h-screen py-10">
      <div className="mb-4 px-3">
        <h1 className="text-4xl font-bold text-gray-800">
          Business Information
        </h1>
        <p className="text-gray-600 mt-2">
          Please fill in your business details
        </p>
      </div>

      {/* Form Section */}
      <div className="bg-white rounded-lg p-8">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(formData);
          }}
          className="grid grid-cols-1 gap-6 md:grid-cols-2 max-w-4xl "
        >
          {/* Business Name */}
          <div className="mb-6">
            <label
              htmlFor="businessName"
              className="block font-medium text-gray-700"
            >
              Business Name
            </label>
            <input
              type="text"
              id="businessName"
              value={formData.businessName}
              onChange={(e) =>
                handleInputChange("businessName", e.target.value)
              }
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your business name"
            />
          </div>

          {/* Business Email */}
          <div className="mb-6">
            <label
              htmlFor="businessEmail"
              className="block font-medium text-gray-700"
            >
              Business Email
            </label>
            <input
              type="email"
              id="businessEmail"
              value={formData.businessEmail}
              onChange={(e) =>
                handleInputChange("businessEmail", e.target.value)
              }
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your business email"
            />
          </div>

          {/* Business Address */}
          <div className="mb-6 col-span-2">
            <label
              htmlFor="address"
              className="block font-medium text-gray-700"
            >
              Business Address
            </label>
            <textarea
              id="address"
              value={formData.address}
              onChange={(e) => handleInputChange("address", e.target.value)}
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your business address"
            />
          </div>

          {/* Upload CAC Document */}
          <div className="mb-6 col-span-2">
            <label
              htmlFor="cacDocument"
              className="block font-medium text-gray-700"
            >
              Upload CAC Document
            </label>
            <input
              type="file"
              id="cacDocument"
              onChange={(e) =>
                handleInputChange(
                  "cacDocument",
                  e.target.files ? e.target.files[0] : null
                )
              }
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="col-span-2 text-center">
            <button
              type="submit"
              className="w-full py-3 px-6 bg-[#F39909] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit Information
            </button>
          </div>
        </form>

        <p className="mt-6  text-gray-600 text-sm">
          By submitting your information, you agree that you have read and
          understood our{" "}
          <a href="/mou" className="text-blue-600">
            MOU
          </a>
          ,{" "}
          <a href="/privacy-policy" className="text-blue-600">
            Privacy Policy
          </a>
          , and{" "}
          <a href="/terms-and-conditions" className="text-blue-600">
            Terms and Conditions
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default BusinessInfoForm;
