import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SIGN_UP, SIGN_IN_USER } from 'src/services/url'

interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  address: string;
}

interface RegisterResponse {
  success: boolean;
  message: string;
  user?: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    address: string;
  };
}

interface LoginCredentials {
  email: string;
  password: string;
}


interface LoginResponse {
  // Define the response structure here
  // For example:
  success: boolean;
  token: string;
  user: {
    id?: string;
    email: string;
    password: string;
    isAdmin:boolean 
  };
}

const BASE_URL = 'https://api.ratokunbo.com/api/v1'


export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<RegisterResponse, UserData>({
      query: (userData) => ({
        url: SIGN_UP,
        method: 'POST',
        body: userData,
      }),
      transformErrorResponse: (
        response: { status: number, data: any },
        meta,
        arg
      ) => response.data
    }),
    login: builder.mutation<LoginResponse, LoginCredentials>({
      query: (credentials) => ({
        url: SIGN_IN_USER,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useRegisterUserMutation, useLoginMutation  } = apiSlice;