import React from "react";
import Rato from "src/assets/rato-letter.png";
import Apple from "src/assets/apple-img-sm.png";
import Android from "src/assets/android-img-sm.png";
import Twitter from "src/assets/twitter-img-sm.png";
import Facebook from "src/assets/facebook-img-sm.png";
import Instagram from "src/assets/instagram-img-sm.png";

const Footer = () => {
  return (
    <div className="py-12">
      <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
        <div className="flex flex-col">
          <div className="flex items-center">
            <img src={Rato} alt="logo" className="w-8 h-8" />
            <p className="ml-2 font-semibold">
              There's more shopping <br />
              in the app
            </p>
          </div>
          <div className="flex mt-6 gap-x-4">
            <a
              href="https://apps.apple.com/us/app/ratokunbo/id6478985438"
              className="px-4 py-3 bg-[#F3F3F3] flex rounded-2xl items-center justify-center"
            >
              <img src={Apple} alt="Apple" className="w-6 h-6" />
              <p className="font-semibold ml-3">iPhone</p>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ratokunbo.mobile"
              className="px-4 py-3 bg-[#F3F3F3] flex rounded-2xl items-center justify-center"
            >
              <img src={Android} alt="Android" className="w-6 h-6" />
              <p className="font-semibold ml-3">Android</p>
            </a>
          </div>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-base mb-2">Buy</h3>
          <p>Registration</p>
          <p>FAQ</p>
          <p>Stores</p>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-base mb-2">Sell</h3>
          <p >Start selling</p>
          <p>Learn to sell</p>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-base mb-2">About Ratokunbo</h3>
          <p>Company info</p>
          <p>News</p>
          <p>Investors</p>
          <p>Careers</p>
          <p>Policy</p>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-base mb-2">Help & Contact</h3>
          <p>Seller info center</p>
          <p>Contact us</p>
          <p>Investors</p>
          <p>Careers</p>
          <p>Policy</p>
        </div>
      </div>

      <hr className="my-16 bg-[#E5E5E5]" />

      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="flex gap-x-3">
          <a
            href="https://www.instagram.com/ratokunbo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Instagram}
              alt="Instagram"
              className="w-6 h-6 cursor-pointer"
            />
          </a>
          <a
            href="https://www.facebook.com/ratokunbo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Facebook}
              alt="Facebook"
              className="w-6 h-6 cursor-pointer"
            />
          </a>
          <a
            href="https://twitter.com/ratokunbo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Twitter}
              alt="Twitter"
              className="w-6 h-6 cursor-pointer"
            />
          </a>
        </div>
        <p className="mt-4 lg:mt-0 lg:text-sm text-xs text-center">
          Copyright © {new Date().getFullYear()} Ratokunbo Ltd. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
