import moment from "moment"

export const formattedDate = (date: any) => {
   return moment(date).format('LL'); 
}

export const calculateCartTotal = (cartItems: any, deliveryCost = 0) => {
   if (!Array.isArray(cartItems)) return 0;
   const itemsTotal = cartItems.reduce((total, item) => {
     const itemTotal = item.product.price * item.quantity;
     return total + itemTotal;
   }, 0);
 
   return (itemsTotal + deliveryCost).toFixed(2);
} 


export const formatCurrency = (amount: number): string => {
  return `₦${amount.toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}
