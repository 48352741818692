import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import Shop from "src/assets/shop.png";

import Product from "src/components/Product";
import { useQuery } from "@tanstack/react-query";
import {
  fetchProducts,
  fetchCategories,
  fetchProductsByCategory,
} from "src/utils/api";
import { SingleProduct } from "src/constants/types";
import SkeletonCard from "src/components/ui/skeleton-card";

// Example category images
import mobileImage from "src/assets/categoriesImages/mobile.png";
import beautyImage from "src/assets/categoriesImages/beauty.jpg";
import computersImage from "src/assets/categoriesImages/computers.png";
import homesImage from "src/assets/categoriesImages/homes.png";
import carsImage from "src/assets/categoriesImages/cars.jpg";
import gamesImage from "src/assets/categoriesImages/games.jpg";
import { useState } from "react";

type CategoryName =
  | "Mobile"
  | "Beauty"
  | "Computers"
  | "Homes"
  | "Cars"
  | "Games";

const categoryImages: Record<CategoryName, string> = {
  Mobile: mobileImage,
  Beauty: beautyImage,
  Computers: computersImage,
  Homes: homesImage,
  Cars: carsImage,
  Games: gamesImage,
};

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryName | null>(
    null
  );

  const { data: allProducts = [], isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const { data: allCategories = [], isLoading: loading } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const { data: categoryProducts = [] } = useQuery({
    queryKey: ["products", selectedCategory],
    queryFn: () => fetchProductsByCategory(selectedCategory!),
    enabled: !!selectedCategory,
  });

  // @ts-ignore
  const productList: SingleProduct[] = allProducts.productList || [];
  // @ts-ignore

  const categoryList = categoryProducts.productList || [];
  if (isLoading || loading) {
    return <SkeletonCard />;
  }
  const allCat = allCategories.categories || [];

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <div className="w-full">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          className="mt-2 mb-2 rounded-md"
        >
          {[1, 2, 3].map((index) => (
            <SwiperSlide key={index}>
              <img
                src={Shop}
                alt="Shop Banner"
                className="w-full h-32 lg:h-[300px] rounded-md"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Product category="New Arrival" products={productList} isNewArrival />
        {/* shop by category */}
        <div className="mt-16">
          <h2 className="font-medium text-2xl text-gray-800">
            Shop by Category
          </h2>
          <div className="w-full my-5 grid grid-cols-2 md:grid-cols-5 lg:grid-cols-7 gap-6">
            <div className="bg-[#F8F8F8]  w-40 h-40 rounded-full overflow-hidden p-1 cursor-pointer flex flex-col justify-center items-center">
              <span className="text-3xl flex items-center">
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.79112 5.27885L5.75462 7.31635C5.68412 7.38685 5.58862 7.42635 5.48912 7.42635C5.38962 7.42635 5.29412 7.38685 5.22412 7.31635L4.23564 6.32635C4.08964 6.17935 4.08964 5.94185 4.23614 5.79535C4.38314 5.64935 4.62013 5.64985 4.76664 5.79585L5.48961 6.52035L7.26062 4.74833C7.40712 4.60183 7.64462 4.60183 7.79112 4.74833C7.93762 4.89483 7.93762 5.13235 7.79112 5.27885ZM10.3511 5.04285L10.0016 4.69333C9.84211 4.53333 9.75461 4.32033 9.75461 4.09483V3.59483C9.75461 2.85033 9.14861 2.24483 8.40461 2.24483H7.90361C7.67711 2.24483 7.46462 2.15733 7.30562 1.99833L6.95012 1.64333C6.42162 1.11933 5.56562 1.12183 5.04112 1.64883L4.69263 1.99833C4.53213 2.15783 4.31964 2.24533 4.09364 2.24533H3.59314C2.85764 2.24583 2.25764 2.83783 2.24414 3.57083C2.24364 3.57883 2.24314 3.58683 2.24314 3.59533V4.09383C2.24314 4.31983 2.15564 4.53233 1.99614 4.69183L1.64214 5.04635C1.64164 5.04785 1.64014 5.04835 1.63914 5.04935C1.11664 5.57835 1.12114 6.43435 1.64764 6.95585L1.99714 7.30635C2.15614 7.46585 2.24414 7.67785 2.24414 7.90385V8.40635C2.24414 9.15035 2.84914 9.75585 3.59314 9.75585H4.09264C4.31914 9.75635 4.53164 9.84385 4.69064 10.0023L5.04712 10.3578C5.30112 10.6103 5.63812 10.7493 5.99662 10.7493H6.00261C6.36311 10.7478 6.70112 10.6058 6.95412 10.3513L7.30462 10.0013C7.46211 9.84435 7.68012 9.75435 7.90262 9.75435H8.40562C9.14812 9.75435 9.75361 9.14985 9.75511 8.40635V7.90485C9.75511 7.67935 9.84262 7.46685 10.0011 7.30735L10.3566 6.95185C10.8816 6.42385 10.8786 5.56735 10.3511 5.04285Z"
                    fill="#2072FF"
                  />
                </svg>
                100%{" "}
              </span>
              <p className="text-sm">refurbished</p>
            </div>
            {allCat.map((cat: any, i: any) => {
              const categoryImage =
                categoryImages[cat.name as CategoryName] || mobileImage;
              return (
                <div key={i} className="flex flex-col items-center">
                  <div
                    className="bg-[#F8F8F8] w-40 h-40 rounded-full overflow-hidden flex items-center justify-center p-1 cursor-pointer"
                    onClick={() =>
                      setSelectedCategory(cat.name as CategoryName)
                    }
                  >
                    <div className="overflow-hidden w-36 h-36 rounded-full p-4">
                      <img
                        alt={cat.name}
                        src={categoryImage}
                        className="w-full h-full object-contain rounded-full"
                      />
                    </div>
                  </div>
                  <p className="text-lg text-center capitalize mt-2">
                    {cat.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <Product category="" products={categoryList} isNewArrival />
        <div className="my-8">
          <img
            src={Shop}
            alt="Logo"
            className="w-full h-32 md:h-[220px] object-cover rounded-md"
          />
        </div>

        <Product
          category="Trending"
          products={productList}
          actionText="See all"
          isRowTwo
        />
      </div>
    </div>
  );
};

export default Home;
