import { EyeIcon, EyeOffIcon } from "lucide-react";
import React, { useState } from "react";

const Settings = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="bg-[#F8F8F8] h-screen py-10">
      <div className="mb-4 px-3">
        <h1 className="text-4xl font-bold text-gray-800">Password</h1>
        <p className="text-gray-600 mt-2">Update your profile details</p>
      </div>

      {/* Form Section */}
      <div className="bg-white rounded-lg p-8">
        <div className="max-w-2xl">
          <p className="mb-6">
            Your password must be at least 8 characters long, and contain at
            least one digit and one non-digit character
          </p>

          {/* New Password Input */}
          <div className="mb-6 relative">
            <label
              htmlFor="newPassword"
              className="block font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter new password"
            />
            <div
              onClick={handleToggleNewPasswordVisibility}
              className="absolute right-3 top-1/2 transform cursor-pointer"
            >
              {showNewPassword ? (
                <EyeOffIcon className="h-5 w-5 text-gray-600" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-600" />
              )}
            </div>
          </div>

          {/* Confirm Password Input */}
          <div className="mb-6 relative">
            <label
              htmlFor="confirmPassword"
              className="block font-medium text-gray-700"
            >
              Confirm Password
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Confirm your password"
            />
            <div
              onClick={handleToggleConfirmPasswordVisibility}
              className="absolute right-3 top-1/2 transform cursor-pointer"
            >
              {showConfirmPassword ? (
                <EyeOffIcon className="h-5 w-5 text-gray-600" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-600" />
              )}
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-8">
            <button
              className="w-full py-3 px-6 bg-[#F39909] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
