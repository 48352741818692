import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCart } from "src/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Minus, Plus, ShoppingCart, Trash2 } from "lucide-react";
import { Button } from "src/components/ui/button";
import { formatCurrency } from "src/utils/helpers";
import SkeletonCard from "src/components/ui/skeleton-card";
import { useDeleteProduct } from "src/utils/api";
import Spinner from "src/components/Spinners";
import "react-toastify/dist/ReactToastify.css";

const Cart = () => {
  const { handleDeleteProduct, isSuccess } = useDeleteProduct();
  const { user } = useSelector((state: any) => state.user);
  const userId: any = user?.userId;
  const [loadingProductId, setLoadingProductId] = useState<string | null>(null);
  const { data: cart, isLoading } = useQuery({
    queryKey: ["cart", userId],
    queryFn: () => getAllCart(userId),
  });
  const [quantityUpdates, setQuantityUpdates] = useState<
    Record<string, number>
  >({});
  const navigate = useNavigate();

  if (isLoading) {
    return <SkeletonCard />;
  }

  const localCart =
    cart?.cart?.items?.map((item: any) => ({
      ...item,
      quantity: quantityUpdates[item._id] || item.quantity,
    })) || [];

  const handleCheckout = () => {
    localStorage.setItem("cartData", JSON.stringify(localCart));
    navigate("/checkout");
  };

  const handleDelete = (productId: string) => {
    setLoadingProductId(productId);
    handleDeleteProduct({ userId, productId });
    if (isSuccess) {
      setLoadingProductId(null);
    }
  };

  const handleIncrease = (id: string) => {
    setQuantityUpdates((prev) => ({
      ...prev,
      [id]: (prev[id] || 0) + 1,
    }));
  };

  const handleDecrease = (id: string) => {
    setQuantityUpdates((prev) => ({
      ...prev,
      [id]: Math.max((prev[id] || 0) - 1, 0),
    }));
  };

  return (
      <div className="lg:w-full">
        <div className=" p-6 mb-8 shadow">
          <p className="font-semibold text-2xl mb-4">Your Cart</p>

          <button
            className="font-bold text-base text-gray-700 hover:text-gray-900"
            onClick={() => navigate(-1)}
          >
            Back to Shopping
          </button>
        </div>
        <div className="">
          {localCart?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {localCart.map((item: any) => (
                <div
                  key={item._id}
                  className="flex flex-col md:flex-row md:items-center p-4 rounded-lg border border-gray-200"
                >
                  <div className="h-32 w-full md:w-40 overflow-hidden rounded-md">
                    <img
                      alt={item.product.name}
                      src={item.product.images[0]}
                      className="h-full w-full object-cover"
                    />
                  </div>

                  {/* Product Details */}
                  <div className="flex flex-col flex-1 p-4">
                    <p className="text-slate-700 font-semibold text-lg">
                      {item.product.name}
                    </p>
                    <div className="flex items-center space-x-2 mt-2">
                      <p className="text-slate-700 font-medium">Condition:</p>
                      <span className="text-sm flex items-center text-green-600 font-medium">
                        Certified Seller
                      </span>
                    </div>
                    <div className="flex space-x-4 items-center mt-2">
                      <p className="text-slate-700 font-semibold text-md">
                        {formatCurrency(item.product.price * item.quantity)}
                      </p>
                      <p className="text-gray-600 text-sm">
                        Qty: {item.quantity}
                      </p>
                    </div>

                    {/* Quantity Controls */}
                    <div className="flex items-center gap-x-4 mt-4">
                      <button
                        className={`w-8 h-8 rounded-full bg-gray-200 flex justify-center items-center ${
                          item.quantity <= 1
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        onClick={() => handleDecrease(item._id)}
                        disabled={item.quantity <= 1}
                      >
                        <Minus size={16} />
                      </button>
                      <div className="text-xl font-semibold">
                        {item.quantity}
                      </div>
                      <button
                        onClick={() => handleIncrease(item._id)}
                        className={`w-8 h-8 rounded-full bg-[#F39909] flex justify-center items-center ${
                          item.quantity >= item.product.countInStock
                            ? "bg-gray-300 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={item.quantity >= item.product.countInStock}
                      >
                        <Plus color="white" size={16} />
                      </button>
                    </div>
                  </div>

                  {/* Delete Button */}
                  <button
                    className="text-red-500 hover:text-red-600 self-start md:ml-4 mt-2 md:mt-0"
                    onClick={() => handleDelete(item.product.id)}
                  >
                    {loadingProductId === item.product.id ? (
                      <Spinner
                        type="tail-spin"
                        color="#F39909"
                        height={20}
                        width={20}
                      />
                    ) : (
                      <Trash2 size={18} />
                    )}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center text-center p-8  rounded-lg mb-4">
              <ShoppingCart size={48} className="text-gray-500 mb-4" />
              <p className="text-lg font-semibold text-gray-700 mb-4">
                Your cart is empty!
              </p>
              <Button
                className="bg-[#F39909] text-white px-4 py-2 rounded-md"
                onClick={() => navigate("/")}
                variant="primary"
              >
                Back to Shopping
              </Button>
            </div>
          )}

          {localCart?.length > 0 && (
            <div className="p-4  flex flex-col md:flex-row justify-start items-center">
              <Button
                onClick={handleCheckout}
                size="lg"
                className="w-auto mt-4"
                variant="primary"
              >
                Proceed to Checkout
              </Button>
              <Button
                size="lg"
                className="w-auto mt-2 ml-4"
                variant="light"
                onClick={() => navigate("/")}
              >
                Continue Shopping
              </Button>
            </div>
          )}
        </div>
      </div>
  );
};

export default Cart;
