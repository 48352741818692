import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { fetchProducts } from "src/utils/api";
import { Product } from "./Products";
import { useState } from "react";

const data = [
  {
    name: "Total Revenue",
    totalAmount: "850,000",
    svg: (
      <svg
        width="59"
        height="44"
        viewBox="0 0 59 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 43.2903L9.09032 26.3742H17.9161L27.1097 14.2387H39.9806L46.9677 1L58 7.61936"
          stroke="#2CBE81"
          stroke-width="0.735484"
        />
        <path
          d="M9.09032 26.3742L1 43.2903H58V7.61936L46.9677 1L39.9806 14.2387H27.1097L17.9161 26.3742H9.09032Z"
          fill="url(#paint0_linear_1780_7025)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1780_7025"
            x1="29.5"
            y1="1"
            x2="29.5"
            y2="43.2903"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2CBE81" stop-opacity="0.4" />
            <stop offset="1" stop-color="#029457" stop-opacity="0.03" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    name: "Total Revenue",
    totalAmount: "850,000",
    svg: (
      <svg
        width="59"
        height="44"
        viewBox="0 0 59 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 43.2903L9.09032 26.3742H17.9161L27.1097 14.2387H39.9806L46.9677 1L58 7.61936"
          stroke="#866BE9"
          stroke-width="0.735484"
        />
        <path
          d="M9.09032 26.3742L1 43.2903H58V7.61936L46.9677 1L39.9806 14.2387H27.1097L17.9161 26.3742H9.09032Z"
          fill="url(#paint0_linear_1780_7055)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1780_7055"
            x1="29.5"
            y1="1"
            x2="29.5"
            y2="43.2903"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#866BE9" stop-opacity="0.4" />
            <stop offset="1" stop-color="#866BE9" stop-opacity="0.03" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    name: "Total Revenue",
    totalAmount: "850,000",
    svg: (
      <svg
        width="59"
        height="44"
        viewBox="0 0 59 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 43.2903L9.09032 26.3742H17.9161L27.1097 14.2387H39.9806L46.9677 1L58 7.61936"
          stroke="#866BE9"
          stroke-width="0.735484"
        />
        <path
          d="M9.09032 26.3742L1 43.2903H58V7.61936L46.9677 1L39.9806 14.2387H27.1097L17.9161 26.3742H9.09032Z"
          fill="url(#paint0_linear_1780_7055)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1780_7055"
            x1="29.5"
            y1="1"
            x2="29.5"
            y2="43.2903"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#866BE9" stop-opacity="0.4" />
            <stop offset="1" stop-color="#866BE9" stop-opacity="0.03" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
];
const Analytics = () => {
  // const [activeFilter, setActiveFilter] = useState<string>("Today");

  // const handleFilterChange = (filter: string) => {
  //   setActiveFilter(filter);
  // };
  const { data: allProducts = [] } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });
  //@ts-ignore

  const productList: Product[] = allProducts.productList?.slice(0, 4) || [];

  const columns: ColumnDef<Product>[] = [
    {
      header: "Product",
      accessorKey: "name",
      cell: ({ row }) => {
        const { name, images } = row.original;
        return (
          <div className="flex items-center space-x-2 w-[200px]">
            <img
              src={images[0] || "https://via.placeholder.com/50"}
              alt={name}
              className="w-10 h-10 rounded"
            />
            <p className="truncate w-40 block">{name}</p>
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ getValue }) => {
        const status = getValue() as string;
        const statusColor =
          status === "available"
            ? "bg-green-100 text-green-600"
            : "bg-red-100 text-red-600";
        return (
          <span
            className={`px-3 py-1 text-sm font-medium rounded-full ${statusColor}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: ({ getValue }) => `$${getValue()}`,
    },
    {
      header: "Quantity",
      accessorKey: "countInStock",
      cell: ({ getValue }) => {
        const stock = getValue() as number;
        const stockColor = stock === 0 ? "text-red-500" : "text-black";
        return (
          <span className={`font-medium ${stockColor}`}>{stock} in Stock</span>
        );
      },
    },
  ];
  const table = useReactTable({
    data: productList,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="md:p-4">
        <div className="flex flex-col  md:flex-row justify-between  items-start md:items-center mb-4">
        <h2 className="md:text-lg font-bold mb-6">Analytics</h2>
        <div className="flex space-x-4">
          {["Today", "This Week", "This Month"].map((filter, i) => (
            <button
              key={i}
              className={`px-4 py-2 rounded-full text-sm font-semibold`}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {data.map((item, index) => (
          <div key={index} className="bg-white shadow p-6  rounded-lg">
            <p className="text-base font-[400] text-gray-600">{item.name}</p>
            <div className="mt-4 flex items-center justify-between">
              <p className="text-xl font-bold text-gray-900">
                {item.totalAmount}
              </p>
              {item.svg}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <h2 className="md:text-lg font-bold mb-4">Top Selling Products</h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead className="bg-gray-100">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-3 text-left text-sm font-semibold"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="py-5 text-sm">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
