
// Users
export const SIGN_UP = "/users/register";
export const SIGN_IN_USER = "/users/login";

//Cart
export const ADD_TO_CART = "/cart/add";
export const GET_ALL_CART = "/cart";
export const DELETE_CART_ITEM = "/cart/remove";

// Orders
export const ORDERS = "/orders";
export const USER_ORDERS = "/orders/get/userorders";
export const ADMIN_ORDERS = "/orders/admin";

//Categories
export const GET_ALL_CATEGORIES = "/categories";