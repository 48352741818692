import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/signUp";
import Login from "./pages/login";
import ForgetPassword from "./pages/forgetPassword";
import Home from "./pages/home";
import { ToastContainer } from "react-toastify";
import { ProductDetailPage } from "./pages/productDetails";
import Layout from "./pages/layout";
import Cart from "./pages/cart";
import Checkout from "./pages/checkout";
import InAppLayout from "./pages/dashbaord/InAppLayout";
import Products from "./pages/dashbaord/Products";
import Orders from "./pages/dashbaord/Orders";
import Analytics from "./pages/dashbaord/Analytics";
import AddProducts from "./pages/dashbaord/AddProducts";
import Profile from "./pages/Profile";
import BecomeSeller from "./pages/BecomeSeller";
import Settings from "./pages/Settings";
import CustomerOrder from "./pages/CustomerProduct";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/product/:productId" element={<ProductDetailPage />} />
          <Route path="cart" element={<Cart />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="profile" element={<Profile />} />
          <Route path="become-seller" element={<BecomeSeller />} />
          <Route path="order" element={<CustomerOrder />} />

          <Route path="settings" element={<Settings />} />
        </Route>
        <Route element={<InAppLayout />}>
          <Route path="product" index element={<Products />} />
          <Route path="add-products" element={<AddProducts />} />
          <Route path="orders" element={<Orders />} />
          <Route path="analytics" element={<Analytics />} />
        </Route>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="login" element={<Login />} />
        <Route path="forget-password" element={<ForgetPassword />} />
      </Routes>
    </>
  );
};

export default App;
