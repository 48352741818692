import { Products } from "src/constants/types";
import axios from "axios";
import { isAxiosError } from "axios";
import apiClient from "./apiClient";
import { ADD_TO_CART, DELETE_CART_ITEM, GET_ALL_CART, GET_ALL_CATEGORIES, ORDERS } from "src/services/url";
import { IOrderProps } from "src/services/orders";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type DeleteProductParams = {
  userId: string;
  productId: string;
};

type ApiResponse = {
  message: string;
};
export const handleError = (error: any) => {
    if (error.response) {
      const { status, data } = error.response;
      console.error(`API Error (${status}): ${data?.message || error.message}`);
      throw new Error(data?.message || `An error occurred (Status: ${status})`);
    } else if (error.request) {
      console.error("No response from server:", error.message);
      throw new Error("Network error, please try again later.");
    } else {
      console.error("Error setting up request:", error.message);
      throw new Error("An unexpected error occurred.");
    }
  };

export const fetchProducts = async (): Promise<Products[]> => {
    const response = await axios.get('https://api.ratokunbo.com/api/v1/products');
    return response.data
};

export const fetchCategories = async () => {
  const response = await apiClient.get(`${GET_ALL_CATEGORIES}`)
  return response.data
}
export const fetchProductsByCategory = async (category: string): Promise<Products[]> => {
  const response = await axios.get(`https://api.ratokunbo.com/api/v1/products/?category=${category}`);
  return response.data;
};


export const addToCartApi = async (data?: any) => {
    const { userId, ...updatedData } = data;
    if (!userId) throw new Error("User ID is required to add to cart");
  
    try {
      const response = await apiClient.post(`${ADD_TO_CART}`, updatedData, {
        headers: {
          userId: userId,
        },
      });
      return response?.data;
    } catch (error) {
      handleError(error);
    }
};

export const getAllCart = async (userId: any) => {
    if (!userId) throw new Error("User ID is required to get cart items");
  
    try {
      const response = await apiClient.get(`${GET_ALL_CART}`, {
        headers: { userId: userId },
      });
      return response?.data;
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 404) {
        return [];
      }
      handleError(error);
    }
};

type DeleteRes = ApiResponse | Error | DeleteProductParams

export const deleteProductFromCart = async ({
  userId,
  productId,
}: DeleteProductParams): Promise<DeleteRes> => {
  try {
    const response = await apiClient.delete(
      `${DELETE_CART_ITEM}/${productId}`,
     {
      headers: { userId: userId },
    });

    // if (response.status !== 200) {
    //   throw new Error(response.data.message || "Failed to delete product");
    // }
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "An unexpected error occurred"
    );
  }
};

export const useDeleteProduct = () => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: deleteProductFromCart,
    onSuccess: () => {
      toast.error('Product deleted', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });
  const queryClient = useQueryClient();

  const handleDeleteProduct = (data: DeleteProductParams) => {
    mutate(data);
  };

  if (isSuccess) {
    queryClient.invalidateQueries({ queryKey: ['cart'] });
  }
  return {
    handleDeleteProduct,
    isPending,
    isSuccess,
  };
};

export const createOrder = async (data: IOrderProps) => {
  const { userId, orderItems } = data;
  try {
    const response = await apiClient.post(
      `${ORDERS}`,
      {
        userId,
        orderItems,
      },
      {
        headers: {
          userId,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};