import { formatCurrency, formattedDate } from "src/utils/helpers";
import { Button } from "../ui/button";
import Product from "src/components/Product";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchProducts } from "src/utils/api";
import { addToCartApi } from "src/utils/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import axios from "axios";

export const SingleProduct = ({ product }: { product: any }) => {
  const { user } = useSelector((state: any) => state.user);
  const userId = user?.userId || [];
  const { data: allProducts = [] } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });
  //@ts-ignore
  const productList: SingleProduct[] = allProducts.productList || [];

  const addToCartMutation = useMutation({
    mutationFn: addToCartApi,
    onSuccess: (data: any) => {
      if (data.success) {
        toast.success("Product added to cart successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    onError: (error: any) => {
      if (axios.isAxiosError(error) && error.response) {
        alert(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Please login to add items to cart", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
  });

  const handleAddToCart = () => {
    const data: any = {
      productId: product.id,
      quantity: 1,
      userId: userId,
    };
    addToCartMutation.mutate(data);
  };

  return (
    <div className="p-2 w-full">
      <div className="lg:w-[80%] p-4">
        <div className="lg:flex gap-x-2 p-4">
          <div className="relative p-1 ">
            <p className="text-slate-700 font-semibold lg:hidden">
              {product.name}
            </p>
            <p className="text-slate-700 font-semibold mb-2 lg:hidden black">
              {formatCurrency(product.realPrice)}{" "}
            </p>
            <div className="h-40 aspect-h-1 lg:py-12 px-8 aspect-w-1  overflow-hidden rounded-md   lg:aspect-none group-hover:opacity-75 lg:w-[470px] lg:h-[413px]">
              <img
                alt={"product"}
                src={product.images[0]}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
          </div>
          <div className="my-2">
            <Carousel className=" lg:ml-8 lg:hidden">
              <CarouselContent className="mx-1">
                {product?.images?.map((img: any, i: any) => (
                  <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3 basis-1/6 p-1 h-20 rounded-lg">
                    <div className="group relative p-1 h-full" key={i}>
                      <div className="aspect-h-1 aspect-w-1 w-full h-full overflow-hidden flex rounded-md bg-[#F39909] lg:aspect-none group-hover:opacity-75">
                        <img
                          alt={img.name}
                          src={img}
                          className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                        />
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          </div>
          <div className=" lg:w-[30%] space-y-4">
            <p className="text-slate-700 font-semibold hidden lg:block">
              {product.name}
            </p>
            <div className="flex items-center space-x-2">
              <p className="text-slate-700 font-medium">Condition:</p>
              <span className="text-sm flex items-center">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.79112 5.27885L5.75462 7.31635C5.68412 7.38685 5.58862 7.42635 5.48912 7.42635C5.38962 7.42635 5.29412 7.38685 5.22412 7.31635L4.23564 6.32635C4.08964 6.17935 4.08964 5.94185 4.23614 5.79535C4.38314 5.64935 4.62013 5.64985 4.76664 5.79585L5.48961 6.52035L7.26062 4.74833C7.40712 4.60183 7.64462 4.60183 7.79112 4.74833C7.93762 4.89483 7.93762 5.13235 7.79112 5.27885ZM10.3511 5.04285L10.0016 4.69333C9.84211 4.53333 9.75461 4.32033 9.75461 4.09483V3.59483C9.75461 2.85033 9.14861 2.24483 8.40461 2.24483H7.90361C7.67711 2.24483 7.46462 2.15733 7.30562 1.99833L6.95012 1.64333C6.42162 1.11933 5.56562 1.12183 5.04112 1.64883L4.69263 1.99833C4.53213 2.15783 4.31964 2.24533 4.09364 2.24533H3.59314C2.85764 2.24583 2.25764 2.83783 2.24414 3.57083C2.24364 3.57883 2.24314 3.58683 2.24314 3.59533V4.09383C2.24314 4.31983 2.15564 4.53233 1.99614 4.69183L1.64214 5.04635C1.64164 5.04785 1.64014 5.04835 1.63914 5.04935C1.11664 5.57835 1.12114 6.43435 1.64764 6.95585L1.99714 7.30635C2.15614 7.46585 2.24414 7.67785 2.24414 7.90385V8.40635C2.24414 9.15035 2.84914 9.75585 3.59314 9.75585H4.09264C4.31914 9.75635 4.53164 9.84385 4.69064 10.0023L5.04712 10.3578C5.30112 10.6103 5.63812 10.7493 5.99662 10.7493H6.00261C6.36311 10.7478 6.70112 10.6058 6.95412 10.3513L7.30462 10.0013C7.46211 9.84435 7.68012 9.75435 7.90262 9.75435H8.40562C9.14812 9.75435 9.75361 9.14985 9.75511 8.40635V7.90485C9.75511 7.67935 9.84262 7.46685 10.0011 7.30735L10.3566 6.95185C10.8816 6.42385 10.8786 5.56735 10.3511 5.04285Z"
                    fill="#2072FF"
                  />
                </svg>
                Certified Seller
              </span>{" "}
            </div>
            <p className="text-slate-700 font-semibold hidden lg:block">
              {formatCurrency(product.realPrice)}{" "}
            </p>
            <div className="mt-4">
              <Button
                variant="primary"
                size={"lg"}
                className="cursor-pointer w-full mt-6"
              >
                Buy Now
              </Button>
              <Button
                variant="light"
                size={"lg"}
                className="cursor-pointer w-full mt-2"
                onClick={handleAddToCart}
              >
                Add to cart
              </Button>
            </div>
            <div className="">
              <p>Detail</p>
              <div className="grid lg:grid-cols-2 grid-cols-2">
                <p className="font-thin text-slate-900">Condition</p>
                <p className="text-sm">Used</p>
                <p className="font-thin text-slate-900">Brand</p>
                <p className="text-sm">{product.brand}</p>
                <p className="font-thin text-slate-900">Posted</p>
                <p className="text-sm whitespace-nowrap	">
                  {formattedDate(product.dateCreated)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-[45%]">
          <Carousel className="w-[50%] lg:ml-8 hidden lg:block">
            <CarouselContent className="ml-1 ">
              {product?.images?.map((img: any, i: any) => (
                <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3 p-1 h-20 rounded-lg">
                  <div className="group relative p-1 h-full" key={i}>
                    <div className="aspect-h-1 aspect-w-1 w-full h-full overflow-hidden flex rounded-md bg-[#F39909] lg:aspect-none group-hover:opacity-75">
                      <img
                        alt={img.name}
                        src={img}
                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                      />
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
          <div className="mt-6 w-full">
            <p className="font-semibold">Description</p>
            <p className="text-sm leading-6">{product.description}</p>
          </div>
        </div>
      </div>
      <Product
        category="Similar Items"
        products={productList}
        actionText="See all"
        isRowTwo
      />
    </div>
  );
};
