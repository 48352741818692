import React, { useState, useEffect } from "react";
import { useAppSelector } from "src/state/hooks";
import { Edit2Icon, EditIcon } from "lucide-react";

const Profile = () => {
  const { user } = useAppSelector((state: any) => state.user);
  console.log(user);
  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      setProfile({
        name: user.firstname,
        phone: user.phone,
        email: user.email,
      });
    }
  }, [user]);

  const handleUpdate = () => {
    console.log("Profile Updated:", profile);
    setIsEditing(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="bg-[#F8F8F8] h-screen py-10">
      {/* Header */}
      <div className=" mb-4 px-3">
        <h1 className="text-4xl font-bold text-gray-800">
          Personal Information
        </h1>
        <p className="text-gray-600 mt-2">Update your profile details</p>
      </div>

      {/* Form Section */}
      <div className="bg-white rounded-lg p-8">
        <div className="max-w-2xl">
          <div className="grid gap-6">
            <div className="relative mb-8 flex justify-center">
              <div className="w-32 h-32 rounded-full overflow-hidden relative">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
                <EditIcon
                  size={24}
                  className="absolute bottom-3 right-3 text-black cursor-pointer transition-all duration-200"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="name"
                className="block text-lg font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={profile.name}
                onChange={handleChange}
                disabled={!isEditing}
                className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your full name"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="phone"
                  className="block text-lg font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={profile.phone}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your phone number"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                />
              </div>
            </div>
          </div>
          ˝{" "}
          <div className="mt-8">
            <button
              onClick={handleUpdate}
              className="w-full md:w-1/2 py-3 px-6 bg-[#F39909] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
