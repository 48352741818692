import {ReactNode} from 'react';

interface TextCardProps {
    title: string;
    children: ReactNode;
  
}


const TextCard: React.FC<TextCardProps> = ({title, children}) => {
  return (
    <div className=" p-4 bg-white rounded-md lg:my-4">
        <p className="text-slate-950 font-semibold">{title}</p>
        {children}
    </div>
  )
}

export default TextCard;
