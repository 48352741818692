import React from 'react'
import { Skeleton } from './skeleton'

const SkeletonCard = () => {

    const skeleton = Array.from({ length: 12 });

  return (
    <div className="grid grid-cols-3 gap-4 p-16">
    {skeleton.map((_, index) => (
      <div key={index} className="space-y-4">
        <Skeleton className="h-32 w-full bg-gray-300 rounded-md" />
        <Skeleton className="h-4 w-3/4 bg-gray-300 rounded" />
        <Skeleton className="h-4 w-1/2 bg-gray-300 rounded" />
      </div>
    ))}
  </div>
  )
}

export default SkeletonCard
