import axios, { AxiosInstance, AxiosResponse } from "axios";

const BASE_URL = "https://api.ratokunbo.com/api/v1";

const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

const logError = (error: any) => {
  const errorDetails = {
    message: error.message,
    response: error.response
      ? {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers,
        }
      : "No response received",
    config: error.config,
  };
  console.error("API Error:", JSON.stringify(errorDetails, null, 2));
};

// Request interceptor to attach accessToken
apiClient.interceptors.request.use(
  async (config: any) => {
    try {
      const token = await localStorage.getItem("accessToken");
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
    return config;
  },
  (error) => {
    logError(error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    logError(error);

    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = await localStorage.getItem("refreshToken");

      if (refreshToken) {
        try {
          const response = await axios.post(`${BASE_URL}/auth/refresh`, {
            refreshToken,
          });
          const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
            response.data;

          await localStorage.setItem("accessToken", newAccessToken);
          await localStorage.setItem("refreshToken", newRefreshToken);

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return apiClient(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          await localStorage.removeItem("accessToken");
          await localStorage.removeItem("refreshToken");
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
