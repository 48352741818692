import React, { useState } from "react";
import { ArrowRight } from "lucide-react";

import { Products } from "src/constants/types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { formatCurrency } from "src/utils/helpers";


type OutletContext = {
  searchQuery: string;
};

interface ProductProps {
  category?: string;
  products: Products[];
  isNewArrival?: boolean;
  isCategory?: boolean;
  title?: string;
  isRowTwo?: boolean;
  actionText?: string;
}
const ProductCard = ({
  product,
  onClick,
}: {
  product: Products;
  onClick: (product: any) => void;
}) => (
  <div
    onClick={() => onClick(product)}
    className="group relative rounded-lg  transition-shadow duration-300 cursor-pointer"
  >
    <div className="w-full h-48 lg:h-56 overflow-hidden rounded-md  p-4">
      <img
        alt={product.name}
        src={product.images[0]}
        className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
      />
    </div>
    <div className="mt-3 flex flex-col space-y-1">
      <h2 className="text-base font-light text-gray-800 truncate">
        {product.name}
      </h2>
      <p className="text-base font-medium   text-[#000000]">
        {formatCurrency(product.price)}
      </p>
      <span className="text-xs text-blue-500 flex items-center">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.79112 5.27885L5.75462 7.31635C5.68412 7.38685 5.58862 7.42635 5.48912 7.42635C5.38962 7.42635 5.29412 7.38685 5.22412 7.31635L4.23564 6.32635C4.08964 6.17935 4.08964 5.94185 4.23614 5.79535C4.38314 5.64935 4.62013 5.64985 4.76664 5.79585L5.48961 6.52035L7.26062 4.74833C7.40712 4.60183 7.64462 4.60183 7.79112 4.74833C7.93762 4.89483 7.93762 5.13235 7.79112 5.27885ZM10.3511 5.04285L10.0016 4.69333C9.84211 4.53333 9.75461 4.32033 9.75461 4.09483V3.59483C9.75461 2.85033 9.14861 2.24483 8.40461 2.24483H7.90361C7.67711 2.24483 7.46462 2.15733 7.30562 1.99833L6.95012 1.64333C6.42162 1.11933 5.56562 1.12183 5.04112 1.64883L4.69263 1.99833C4.53213 2.15783 4.31964 2.24533 4.09364 2.24533H3.59314C2.85764 2.24583 2.25764 2.83783 2.24414 3.57083C2.24364 3.57883 2.24314 3.58683 2.24314 3.59533V4.09383C2.24314 4.31983 2.15564 4.53233 1.99614 4.69183L1.64214 5.04635C1.64164 5.04785 1.64014 5.04835 1.63914 5.04935C1.11664 5.57835 1.12114 6.43435 1.64764 6.95585L1.99714 7.30635C2.15614 7.46585 2.24414 7.67785 2.24414 7.90385V8.40635C2.24414 9.15035 2.84914 9.75585 3.59314 9.75585H4.09264C4.31914 9.75635 4.53164 9.84385 4.69064 10.0023L5.04712 10.3578C5.30112 10.6103 5.63812 10.7493 5.99662 10.7493H6.00261C6.36311 10.7478 6.70112 10.6058 6.95412 10.3513L7.30462 10.0013C7.46211 9.84435 7.68012 9.75435 7.90262 9.75435H8.40562C9.14812 9.75435 9.75361 9.14985 9.75511 8.40635V7.90485C9.75511 7.67935 9.84262 7.46685 10.0011 7.30735L10.3566 6.95185C10.8816 6.42385 10.8786 5.56735 10.3511 5.04285Z"
            fill="#2072FF"
          />
        </svg>
        Certified Seller
      </span>
    </div>
  </div>
);
const Product: React.FC<ProductProps> = ({
  category,
  products,
  isNewArrival,
  isRowTwo,
  actionText,
}) => {
  const navigate = useNavigate();
  const { searchQuery } = useOutletContext<OutletContext>();
  const [showAll, setShowAll] = useState(false);
  

  const handleClick = (product: { _id: any; }) => {
    navigate(`/product/${product._id}`, { state: { product } });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedProducts = showAll ? filteredProducts : filteredProducts.slice(0, 15);

  return (
    <section className="mt-16">
      {isRowTwo && (
        <div>
          <div className="flex justify-between mb-5">
            <h2 className="font-medium text-2xl text-gray-800">{category}</h2>
            {actionText && (
              <p
                className="flex text-[#F39909] items-center cursor-pointer lg:text-md text-md"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "View Less" : actionText} <ArrowRight className="ml-1 h-4" />
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-6">
            {displayedProducts.map((product, i) => (
              <ProductCard product={product} onClick={handleClick} key={i} />
            ))}
          </div>
        </div>
      )}
      {isNewArrival && (
        <div className="">
          <h2 className="font-medium text-2xl text-gray-800 mb-5">{category}</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-6">
            {filteredProducts.slice(0, 4).map((product, i) => (
              <ProductCard product={product} onClick={handleClick} key={i} />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default Product;
