import React, { useState } from "react";
import Logo from "src/assets/logo.svg";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Eye, EyeOff, Loader2 } from "lucide-react";
import { useLoginMutation } from "src/state/user/apiSlice";
import { useNavigate } from "react-router-dom";
import { setUser } from "src/redux/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [userLogin, setLogin] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const response = await login(userLogin).unwrap();
      saveToLocalStorage(response);
      dispatch(setUser(response.user));
      toast.success("Login successful!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (response.user.isAdmin) {
        navigate("/product");
      } else {
        navigate("/");
      }
    } catch (err) {
      toast.error(
        "Login failed. Please check your credentials and try again.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      console.error("Failed to login:", err);
    }
  };

  const saveToLocalStorage = (response: any) => {
    localStorage.setItem("userSession", JSON.stringify(response));
  };

  const disabled = Object.values(userLogin).some((text) => text === "");

  return (
    <div className="h-screen w-full">
      <div className="lg:px-16 p-4 flex items-center justify-between ">
        <img src={Logo} alt="Logo" className="lg:w-64 lg:h-28 h:16 w-48" />
        <p className="text-sm text-right">
          Don't have an account?
          <span
            className="text-md font-semibold ml-2 cursor-pointer"
            onClick={() => navigate("/sign-up")}
          >
            Sign up
          </span>
        </p>
      </div>
      <div className="w-full flex justify-center lg:mt-32 mt-16">
        <div className="p-4 lg:w-[35%] w-full">
          <p className="text-2xl font-bold">Log In</p>
          <Input
            type="email"
            name="email"
            placeholder="Email address"
            onChange={handleChange}
            className="my-8 bg-[#F2F2F2] py-6"
          />
          <div className="flex mt-4 mb-8 relative">
            <Input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              onChange={handleChange}
              className="bg-[#F7F7F7] py-6"
            />
            {showPassword ? (
              <EyeOff
                onClick={togglePasswordVisibility}
                className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400"
              />
            ) : (
              <Eye
                onClick={togglePasswordVisibility}
                className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400"
              />
            )}
          </div>
          <Button
            onClick={handleLogin}
            className="w-full bg-[#F39909] mt-8 cursor-pointer"
            variant="rato"
            size={"lg"}
            disabled={disabled}
          >
            {isLoading ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Continue"
            )}
          </Button>
          <p
            className="text-center cursor-pointer mt-16 font-semibold"
            onClick={() => navigate("/forget-password")}
          >
            Forget Password?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
