// Layout.js
import React, { useState } from "react";
import Logo from "src/assets/logo.svg";
// import { ChevronDown, MenuIcon } from "lucide-react";
import { MenuIcon } from "lucide-react";
import { FiShoppingCart } from "react-icons/fi";
import { PiBellLight } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { IoChevronDown } from "react-icons/io5";
import { Input } from "../components/ui/input";
import { getAllCart } from "src/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./footer";
import { logout } from "src/redux/userSlice";
import { CatogeryList } from "src/data/categories";

import { Outlet, useNavigate } from "react-router-dom";
import SearchField from "src/components/Search";
import { Link } from "react-router-dom";

const Categories = ({ onSearch }: { onSearch: any }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex gap-6 items-center px-4 md:px-8 py-2 xl:py-0 xl:h-[50px]">
      <div className="lg:hidden relative">
        <button
          type="button"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Menu"
        >
          <MenuIcon className="size-6" />
        </button>

        {/* Mobile Dropdown Menu */}
        {menuOpen && (
          <div className="xl:hidden bg-white shadow-md border-t absolute z-10 mt-4 min-w-[200px]">
            {/* TODO: Temporarily Remove Nav */}
            {/* <div className="flex flex-col px-4 py-2 gap-2">
              {[
                "Deals",
                "Cell Phone",
                "Office Supplies",
                "Furniture",
                "Appliances",
                "Electronics",
                "Home Living",
                "Wearable",
                "Health & Fitness",
                "Watches",
                "TV's",
                "Car Tech",
                "Computers",
              ].map((category) => (
                <p
                  key={category}
                  className="block py-1 hover:text-[#F39909] transition-colors  cursor-pointer"
                >
                  {category}
                </p>
              ))}
            </div> */}
          </div>
        )}
      </div>

      <div className="flex lg:hidden w-full">
        <Input
          type="text"
          name="address"
          placeholder="Search for anything..."
          onChange={(e) => onSearch(e.target.value)}
          className="bg-[#F7F7F7] py-1 rounded-lg"
        />{" "}
      </div>

      {/* <div className="hidden lg:flex items-center gap-6">
        {[
          "Deals",
          "Cell Phone",
          "Office Supplies",
          "Furniture",
          "Appliances",
          "Electronics",
          "Home Living",
          "Wearable",
          "Health & Fitness",
          "Watches",
          "TV's",
          "Car Tech",
          "Computers",
        ].map((category) => (
          <p
            key={category}
            className="hover:text-[#F39909] transition-colors font-medium text-sm cursor-pointer"
          >
            {category}
          </p>
        ))}
      </div> */}
    </div>
  );
};
const Layout = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state: any) => state.user);
  const userId = user?.userId;
  const isAdmin = user?.isAdmin;
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { data: cart } = useQuery({
    queryKey: ["cart", userId],
    queryFn: () => getAllCart(userId),
  });

  const items = cart?.cart?.items || [];

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="overflow-hidden">
      {" "}
      <div className="container mx-auto px-4 md:px-12 py-2">
        <div className=" flex items-center justify-between gap-4">
          <img
            src={Logo}
            alt="Logo"
            className="w-[126px] md:w-[186px] lg:w-[226px]"
            onClick={() => navigate("/")}
          />
          <div className="dropdown dropdown-hover">
            {/* <button
              type="button"
              tabIndex={0}
              className="hidden xl:flex items-center gap-1 text-sm font-medium"
            >
              Shop by <br /> Category
              <ChevronDown className="cursor-pointer" />{" "}
            </button> */}

            <div
              tabIndex={0}
              className="dropdown-content z-10 menu mt-2 bg-base-100 border-2 rounded-box w-[800px] shadow text-black grid grid-cols-3 gap-4 p-4"
            >
              {CatogeryList.map((cat, i) => (
                <div key={i}>
                  <a
                    href="/"
                    className="block text-slate-900 font-semibold mb-4"
                  >
                    {cat.category}
                  </a>
                  <ul className="space-y-1">
                    {cat.type.map((typeItem, typeIndex) => (
                      <li
                        key={typeIndex}
                        className="block py-1  text-sm hover:bg-gray-50 hover:pl-4 duration-100"
                      >
                        {typeItem.title}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <SearchField onSearch={handleSearch} />
          <div className="flex items-center gap-5  ">
            <div className="flex items-center gap-4">
              <button
                type="button"
                aria-label="Cart"
                className="relative"
                onClick={() => {
                  !isAuthenticated ? navigate("/login") : navigate("/cart");
                }}
              >
                {items && items?.length > 0 && (
                  <div className="w-3 h-3 bg-[#FF3B30] rounded-full absolute left-4 -top-1 flex justify-center items-center">
                    <p className="text-white text-xs">{items.length}</p>
                  </div>
                )}

                <FiShoppingCart className="size-6" />
              </button>
              <button type="button" aria-label="Notification">
                <PiBellLight className="size-6" />
              </button>
            </div>
            {!isAuthenticated ? (
              <div className="flex  items-center  space-x-4">
                <div
                  className="hidden md:flex text-sm text-[#000000]
             whitespace-nowrap cursor-pointer
           "
                  onClick={() => navigate("/login")}
                >
                  Log in
                </div>
                <div
                  className=" py-2   text-sm px-4 text-white
            rounded-3xl bg-orange-400 flex items-center justify-center
            hover:grow cursor-pointer whitespace-nowrap  
           "
                  onClick={() => navigate("/sign-up")}
                >
                  Sign up
                </div>
              </div>
            ) : (
              <div
                tabIndex={0}
                className="dropdown dropdown-hover dropdown-bottom dropdown-end flex items-center gap-1 text-sm font-medium z-10"
              >
                <CgProfile className="size-6" />
                <span className="hidden lg:block flex-grow truncate">
                  {user?.firstname}
                </span>
                <IoChevronDown className="size-4" />

                <div
                  tabIndex={0}
                  className="dropdown-content cursor-pointer mt-2 menu bg-base-100 border-2 py-2 px-0 rounded-box z-[1] w-52 shadow text-black text-start flex flex-col divide-y divide-gray-100"
                >
                  {isAdmin ? (
                    <p
                      onClick={() => navigate("/product")}
                      className="h-10 flex items-center justify-center px-2 hover:bg-gray-50 duration-100"
                    >
                      Admin Dashboard
                    </p>
                  ) : (
                    [
                      { href: "/profile", label: "Profile" },
                      { href: "/order", label: "Order" },
                      { href: "/become-seller", label: "Become a seller" },
                      { href: "", label: "Favorite Item" },
                      { href: "", label: "Contact Us" },
                      { href: "/settings", label: "Settings" },

                    ].map((item) => (
                      <Link to={item.href}
                        key={item.label}
                        className="h-10 flex items-center px-2 hover:pl-4 hover:bg-gray-50 duration-100"
                      >
                        {item.label}
                      </Link>
                    ))
                  )}

                  <p
                    onClick={handleLogout}
                    className="h-10 flex items-center justify-center text-red-500 px-2 hover:bg-gray-50 duration-100"
                  >
                    Log out
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Categories onSearch={handleSearch} />
        <Outlet context={{ searchQuery }} />
      </div>
      <div className=" container mx-auto border-t-2 mt-12 px-4 md:px-12">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
