export const CatogeryList = [
    {
        category: 'Electronics',
        type: [
            {
                title: 'Computer & tablets',
                url: '/'
            },
            {
                title: 'Cameras & Photo',
                url: '/'
            },
            {
                title: 'Tv, audio & surveillance',
                url: '/'
            },
            {
                title: 'Cell phone & accesorries',
                url: '/'
            }
        ]
    },
    {
        category: 'Fashion',
        type: [
            {
                title: 'Women',
                url: '/'
            },
            {
                title: 'Men',
                url: '/'
            },
            {
                title: 'Jewelry & Watches',
                url: '/'
            },
            {
                title: 'Shoes',
                url: '/'
            }
        ]
    },
    {
        category: 'Toys & Hobbies',
        type: [
            {
                title: 'Women',
                url: '/'
            },
            {
                title: 'Men',
                url: '/'
            },
            {
                title: 'Jewelry & Watches',
                url: '/'
            },
            {
                title: 'Shoes',
                url: '/'
            }
        ]
    },
    {
        category: 'Toys & Hobbies',
        type: [
            {
                title: 'Women',
                url: '/'
            },
            {
                title: 'Men',
                url: '/'
            },
            {
                title: 'Jewelry & Watches',
                url: '/'
            },
            {
                title: 'Shoes',
                url: '/'
            }
        ]
    },
    {
        category: 'Toys & Hobbies',
        type: [
            {
                title: 'Women',
                url: '/'
            },
            {
                title: 'Men',
                url: '/'
            },
            {
                title: 'Jewelry & Watches',
                url: '/'
            },
            {
                title: 'Shoes',
                url: '/'
            }
        ]
    },
    {
        category: 'Toys & Hobbies',
        type: [
            {
                title: 'Women',
                url: '/'
            },
            {
                title: 'Men',
                url: '/'
            },
            {
                title: 'Jewelry & Watches',
                url: '/'
            },
            {
                title: 'Shoes',
                url: '/'
            }
        ]
    }
];