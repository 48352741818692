import { useLocation, useParams } from "react-router-dom";
import { SingleProduct } from "src/components/Product/product";

export const ProductDetailPage = () => {
    const { productId } = useParams<{ productId: string }>();
    const location = useLocation();
    const product = location.state?.product;
  
    if (!productId || !product) {
      return <div>Product not found.</div>;
    }
  
    return <SingleProduct product={product} />;
};