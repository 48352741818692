// import { ICartItems } from "@/constants/types";

/**
 * Generate a random transaction reference.
 * @param length The length of the transaction reference to generate.
 * @returns A randomly generated transaction reference.
 */
export const generateTransactionRef = (length: number): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `flw_tx_ref_${result}`;
};

export const calculateTotalAmount = (cartItems: any) => {
  const cart = cartItems || [];

  return cart.reduce((total: any, item: any) => {
    const price = item?.product?.price ? parseFloat(item.product.price) : 0;
    const quantity = parseInt(item.quantity, 10) || 0;
    return total + price * quantity;
  }, 0);
};
