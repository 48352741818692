import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { DashboardIcon } from "@radix-ui/react-icons";
import { FiShoppingCart } from "react-icons/fi";
import { ChartBar } from "lucide-react";
import InAppNav from "./InAppNav";
import { CgClose } from "react-icons/cg";

const InAppLayout = () => {
  const [activeLink, setActiveLink] = useState("product");

  return (
    <div className="h-screen flex flex-col">
      <InAppNav />
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        {/* Sidebar */}
        <div className="drawer-side">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <ul className="flex flex-col space-y-7 p-4 w-56 min-h-full  text-base-content bg-[#F2F2F2]  ">
            <li className="md:hidden absolute top-4 right-4">
              <label htmlFor="my-drawer-2" className="cursor-pointer">
                <CgClose className="w-4 h-4 text-gray-500" />
              </label>
            </li>
            <li
              className={`flex items-center  p-2 rounded-md cursor-pointer ${
                activeLink === "product" ? "bg-gray-300" : ""
              }`}
              onClick={() => setActiveLink("product")}
            >
              <DashboardIcon className="w-5 h-5 mr-2" />
              <Link to="product">Products</Link>
            </li>

            <li
              className={`flex items-center p-2 rounded-md cursor-pointer ${
                activeLink === "orders" ? "bg-gray-300" : ""
              }`}
              onClick={() => setActiveLink("orders")}
            >
              <FiShoppingCart className="w-5 h-5 mr-2" />
              <Link to="orders">Orders</Link>
            </li>
            <li
              className={`flex items-center p-2 rounded-md cursor-pointer ${
                activeLink === "analytics" ? "bg-gray-300" : ""
              }`}
              onClick={() => setActiveLink("analytics")}
            >
              <ChartBar className="w-5 h-5 mr-2" />
              <Link to="analytics">Analytics</Link>
            </li>
          </ul>
        </div>
        <div className="drawer-content p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InAppLayout;
