import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "src/utils/api";
import { DashIcon } from "@radix-ui/react-icons";
import { Button } from "src/components/ui/button";
import { useNavigate } from "react-router-dom";

export type Product = {
  id: string;
  name: string;
  images: string[];
  status: string;
  price: number;
  countInStock: number;
};
const Products = () => {
  const { data: allProducts = [], isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });
  const navigate = useNavigate();
  //@ts-ignore
  const productList: Product[] = allProducts.productList || [];

  const columns: ColumnDef<Product>[] = [
    {
      header: "Product",
      accessorKey: "name",
      cell: ({ row }) => {
        const { name, images } = row.original;
        return (
          <div className="flex items-center space-x-2 w-[200px]">
            <img
              src={images[0] || "https://via.placeholder.com/50"}
              alt={name}
              className="w-10 h-10 rounded"
            />
            <p className="truncate w-40 block">{name}</p>
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ getValue }) => {
        const status = getValue() as string;
        const statusColor =
          status === "available"
            ? "bg-green-100 text-green-600"
            : "bg-red-100 text-red-600";
        return (
          <span
            className={`px-3 py-1 text-sm font-medium rounded-full ${statusColor}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: ({ getValue }) => `₦${getValue()}`,
    },
    {
      header: "Quantity",
      accessorKey: "countInStock",
      cell: ({ getValue }) => {
        const stock = getValue() as number;
        const stockColor = stock === 0 ? "text-red-500" : "text-black";
        return (
          <span className={`font-medium ${stockColor}`}>{stock} in Stock</span>
        );
      },
    },
    {
      header: "",
      accessorKey: "id",
      cell: () => <DashIcon />,
    },
  ];

  const table = useReactTable({
    data: productList || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  if (isLoading) {
    return <p>Loading products...</p>;
  }

  return (
    <div className="md:p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold ">Products</h2>
        <Button
          size="sm"
          className="w-auto"
          variant="primary"
          onClick={() => navigate("/add-products")}
        >
          Add Product
        </Button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead className="bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-3 text-left text-sm font-semibold"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-3 text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center space-x-2">
          <button
            className="p-2 bg-gray-300 rounded ml-2"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Prev
          </button>
          <button
            className="p-2 bg-gray-300 rounded ml-2"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <span>
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            className="p-1 border rounded"
          >
            {[5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} rows
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Products;
