import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { SearchIcon } from "lucide-react";

interface SearchFieldProps {
  onSearch: (query: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const debouncedSearch = React.useMemo(
    () => debounce(onSearch, 300),
    [onSearch]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="flex-1 ">
      <div className="relative hidden lg:flex overflow-hidden">
        <SearchIcon className="absolute top-0 bottom-0 size-6 my-auto text-gray-400 left-5" />

        <input
          type="text"
          placeholder="Search for anything"
          value={query}
          name="address"
          onChange={handleSearch}
          className="w-full py-2 pl-14 pr-8 text-[#747272] rounded-full outline-none bg-[#F8F8F8] border border-transparent focus:bg-white focus:border focus:border-orange-500"
        />
      </div>
    </div>
  );
};

export default SearchField;
