import React, { useState, useEffect } from "react";
import Logo from "src/assets/logo.svg";
import { Button } from '../components/ui/button';
import { Loader2 } from 'lucide-react';
import { Input } from '../components/ui/input';
import { useRegisterUserMutation } from 'src/state/user/apiSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


const SignUp = () => {
    const [registerUser, {  isError, error: err, }] = useRegisterUserMutation();
    const [user, setUser] = useState({ firstname:"", 
    lastname:"", email:"", 
    password:"", phone:"", address:""})

    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState<string>('');
    const [timer, setTimer] = useState(5); 
    const [loading, setLoading] = useState(false);
    const [resend, setResend] = useState(false); 

    const handleChange = (e: any) => {
        setUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    

    const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => { 
      setConfirmPassword(e.target.value);
    }
    
    const handleComplete = (completedOtp: string) => {
      setOtp(completedOtp);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await registerUser(user).unwrap();
        // Handle successful registration
        setLoading(false);
        toast.success('Registration successful!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/login');
      } catch (err) {
        setLoading(false);
        
        console.error('Failed to register:', err);
      }
      // Here you would typically send the OTP to your server for verification
      // console.log('Submitting OTP:', otp);
      // if(status === 'succeeded'){
      //   toast({
      //     title: "Account Successfully Created",
      //     // description: `Your OTP ${otp} has been submitted for verification.`,
      //   });
      //   navigate('/login')
      // }
    };
  

    // console.log(error)


    const handleResend = () => {
      setTimer(5); 
      setResend(false);
    };
    const handleSignup = () => {
        setStep(step + 1)
    }
    // useEffect(() => {
    //     const countdown = setInterval(() => {
    //       if (timer > 0) {
    //         setTimer(timer - 1);
    //       } else {
    //         setResend(true);
    //         clearInterval(countdown);
    //       }
    //     }, 1000); // Update every second
    
    //     return () => clearInterval(countdown);
    //   }, [timer]);

      const checkPassword = user.password !== confirmPassword;
    
      const disabled = Object.values(user).some((text) => text === "") || checkPassword

      const passwordValid = user?.password.length >= 8 && /\d/.test(user?.password) && /[a-z]/.test(user?.password) && /[A-Z]/.test(user?.password);

      useEffect(()=> { 
        if(!err) return;
        const { error, success } = err as {error: string, success: boolean} 

        if(success === false){ 
          toast.error(error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined, 
          });
        }
      }, [isError, err]) 

  return (
    <div className='h-screen w-full'>
      <div className='lg:px-16 p-4 flex items-center justify-between '> 
        <img src={Logo} alt="Logo" className="lg:w-64 lg:h-28 h:16 w-48" />
        <p className="text-sm text-right">Already have an account?<span className='text-md font-semibold ml-2 cursor-pointer' onClick={()=> navigate('/login')}>Log in</span></p>
      </div>
      <div className='w-full flex justify-center'>
       {step === 1 && <div className='p-4 lg:w-[35%]'>
          <p className='text-2xl font-bold'>Create an account</p>
          <div className='flex w-full justify-between my-8'>
           <Input type="text" name="firstname" placeholder='First name' onChange={handleChange} className='w-[48%] bg-[#F7F7F7] py-6'/>
           <Input type="text" name="lastname" placeholder='Last name' onChange={handleChange} className='w-[48%] bg-[#F7F7F7] py-6'/>
          </div>
          <Input type="text" name="address" placeholder='Address' onChange={handleChange} className='bg-[#F7F7F7] py-6'/>
          <Input type="email" name="email" placeholder='Email address' onChange={handleChange} className='my-8 bg-[#F7F7F7] py-6'/>
          <Input type="text" name="phone" placeholder='Phone number' onChange={handleChange} className='my-8 bg-[#F7F7F7] py-6'/>
          <Input type="password" name="password" placeholder='Password' onChange={handleChange} className='my-8 bg-[#F7F7F7] py-6'/>
          <Input type="password" name="confirmPassword" placeholder='Confirm password' onChange={handleConfirmPassword} className='mt-8 mb-2 bg-[#F7F7F7] py-6'/>
          {checkPassword && <p className='text-rose-800 text-xs ml-2'>password does not match</p>}
          {!passwordValid && <p className='text-rose-800 text-xs ml-2'>At least 1 letter, a number or symbol, at least 8 characters.</p>}
          <Button 
           onClick={handleSubmit} 
           className='w-full bg-[#F39909] mt-8 cursor-pointer' 
           variant="rato" 
           size={"lg"}
           disabled={disabled}
           >{loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Continue'}
           </Button>

        </div>}
        {/* {step === 2 && 
        <div className="w-[30%] p-4 flex flex-col gap-4">
        <h2 className="text-4xl font-bold">Verify your phone number</h2>
        <p className='text-bold text-sm ml-2'>An OTP has been sent to your phone number ending with {user?.phone} .</p>
        <OtpInput length={4} onComplete={handleComplete} />
        <p className='text-[#585866] text-sm ml-4'>Didn’t receive OTP?
            {timer > 0 ? (
            <span className="ml-1 text-slate-800 text-bold">{timer} seconds</span>
        ) : (
          (<span onClick={handleResend} className="text-[#F39909] text-sm text-bold ml-2">Resend</span>)
        )}
        
        </p>
        <Button 
         size="lg" 
         onClick={handleSubmit} 
         disabled={otp.length < 4}
         variant="rato"
         className={`w-full ${otp.length === 4? 'bg-[#F39909]' : 'bg-[#e8cea4]'} cursor-pointer text-white mt-8`}
         >Continue</Button>
        </div>
        } */}
      </div>
    </div>
  )
}

export default SignUp;