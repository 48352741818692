import React from "react";
import {
  Circles,
  TailSpin,
  ThreeDots,
  Bars,
  Puff,
} from "react-loader-spinner";

type SpinnerProps = {
  type?: "circles" | "tail-spin" | "three-dots" | "bars" | "puff"; // Spinner types
  color?: string;              // Spinner color
  height?: number;             // Spinner height
  width?: number;              // Spinner width
  visible?: boolean;           // Visibility of the spinner
  className?: string;          // Custom class for styling
};

const Spinner: React.FC<SpinnerProps> = ({
  type = "circles",
  color = "#36D7B7",
  height = 80,
  width = 80,
  visible = true,
  className = "",
}) => {
  // Render the appropriate spinner based on the type
  const renderLoader = () => {
    switch (type) {
      case "circles":
        return (
          <Circles
            height={height}
            width={width}
            color={color}
            visible={visible}
            ariaLabel="loading-spinner"
          />
        );
      case "tail-spin":
        return (
          <TailSpin
            height={height}
            width={width}
            color={color}
            visible={visible}
            ariaLabel="loading-spinner"
          />
        );
      case "three-dots":
        return (
          <ThreeDots
            height={height}
            width={width}
            color={color}
            visible={visible}
            ariaLabel="loading-spinner"
          />
        );
      case "bars":
        return (
          <Bars
            height={height}
            width={width}
            color={color}
            visible={visible}
            ariaLabel="loading-spinner"
          />
        );
      case "puff":
        return (
          <Puff
            height={height}
            width={width}
            color={color}
            visible={visible}
            ariaLabel="loading-spinner"
          />
        );
      default:
        return null;
    }
  };

  return <div className={`flex justify-center items-center ${className}`}>{renderLoader()}</div>;
};

export default Spinner;
