import React from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

interface Order {
  id: string;
  customerName: string;
  status: "Pending" | "Completed" | "Cancelled";
  date: string;
  totalAmount: number;
}

const CustomerOrder = () => {
  const orders: Order[] = [
    {
      id: "001",
      customerName: "John Doe",
      status: "Pending",
      date: "2025-01-01",
      totalAmount: 150.0,
    },
    {
      id: "002",
      customerName: "Jane Smith",
      status: "Completed",
      date: "2025-01-02",
      totalAmount: 200.0,
    },
    {
      id: "003",
      customerName: "Samuel Lee",
      status: "Cancelled",
      date: "2025-01-03",
      totalAmount: 90.0,
    },
  ];

  const columns: ColumnDef<Order>[] = [
    {
      header: "Order ID",
      accessorKey: "id",
    },
    {
      header: "Customer Name",
      accessorKey: "customerName",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ getValue }) => {
        const status = getValue() as "Pending" | "Completed" | "Cancelled";
        const statusColor =
          status === "Completed"
            ? "bg-green-100 text-green-600"
            : status === "Pending"
            ? "bg-yellow-100 text-yellow-600"
            : "bg-red-100 text-red-600";

        return (
          <span
            className={`px-3 py-1 text-sm font-medium rounded-full ${statusColor}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "Date",
      accessorKey: "date",
    },
    {
      header: "Total Amount",
      accessorKey: "totalAmount",
      cell: ({ getValue }) => `₦${getValue()}`,
    },
    // {
    //   header: 'Actions',
    //   accessorKey: 'id',
    //   cell: ({ row }) => (
    //     <button className="px-4 py-2 bg-blue-500 text-white rounded">View</button>
    //   ),
    // },
  ];

  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="p-4" >
      <h2 className="text-xl font-bold mb-6 px-4">Orders</h2>
      <div className="grid grid-cols-4 gap-4 mb-6 bg-white p-4 rounded-lg">
        <div className=" p-4 rounded-lg border-r">
          <h3 className="">Total Orders</h3>
          <p className="text-xl font-bold">0</p>
        </div>
        <div className=" p-4 rounded-lg border-r">
          <h3 className="">Delivered Orders</h3>
          <p className="text-xl font-bold">0</p>
        </div>
        <div className=" p-4 rounded-lg border-r">
          <h3 className="">Fulfilled Orders</h3>
          <p className="text-xl font-bold">0</p>
        </div>
        <div className=" p-4 rounded-lg">
          <h3 className="">Returned Orders</h3>
          <p className="text-xl font-bold">0</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse ">
          <thead className="bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-3 text-left text-sm font-semibold"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50 ">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="py-5  px-4 text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerOrder;
