import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import { useSelector } from "react-redux";
import Promo from "src/assets/promo.png";
import TextCard from "src/components/Card/textCard";
import { calculateCartTotal, formatCurrency } from "src/utils/helpers";
import { MapPin, NotebookPen } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import Payment from "src/components/Payment/payment";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const { user } = useSelector((state: any) => state.user);
  const [cartData, setCartData] = useState<any[]>([]);
  const [showPayment, setShowPayment] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const savedCartData = localStorage.getItem("cartData");
    if (savedCartData) {
      setCartData(JSON.parse(savedCartData));
    }
  }, []);

  const deliveryCost = 1000;
  const totalPrice = calculateCartTotal(cartData, deliveryCost);

  const handleCheckout = () => {
    setShowPayment(true);
  };

  return (
    <div className="lg:py-4 lg:px-16 p-4 bg-[#F7F7F7] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6">
      <div className="">
        <p className="font-semibold text-2xl">Your Cart</p>
        {cartData.length > 0 ? (
          cartData.map((item: any) => (
            <div className="lg:flex flex lg:py-2 lg:px-6 p-4 bg-white lg:gap-x-3 mb-3 gap-x-3">
              <div className="relative p-1">
                <div className="h-20 w-20 aspect-h-1 lg:px-8 aspect-w-1 lg:w-[300px] lg:h-44 overflow-hidden rounded-md bg-[#F8F8F8] lg:aspect-none group-hover:opacity-75 ">
                  <img
                    alt={"product"}
                    src={item.product.images[0]}
                    className="h-full w-full object-cover lg:h-full lg:w-full"
                  />
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <p className="text-slate-700 lg:text-lg text-xs font-semibold">
                    {item.product.name}
                  </p>
                  <div className="w-full flex justify-between gap-x-8 items-center">
                    <p className="text-slate-700 font-semibold lg:text-lg text-xs">
                      {formatCurrency(
                        Math.round(item.product.price * item.quantity * 100) /
                          100
                      )}
                    </p>
                    {/* <p className="text-slate-700 font-semibold lg:text-lg text-xs">Qty: <span>{item.product.countInStock}</span></p> */}
                  </div>
                </div>
                <p className="text-[#EB5454] underline underline-offset-1 cursor-pointer lg:text-lg text-xs">
                  Remove
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center p-6 bg-white mt-12">
            <p className="text-gray-600 text-lg mb-4">
              Your cart is empty. Add products to proceed to checkout.
            </p>
            <Button
              onClick={() => navigate("/")}
              className="w-full"
              variant="primary"
            >
              Go Back
            </Button>
          </div>
        )}
        {cartData.length > 0 && (
          <>
            <TextCard title="Promotion">
              <div className=" flex gap-x-2">
                <div className="bg-[#F7F7F7] flex w-full rounded-md gap-x-2">
                  <img src={Promo} alt="" className="" />
                  <input
                    className="bg-[#F7F7F7] border-none focus:outline-none focus:ring-0"
                    placeholder="Enter promo code"
                  />
                </div>
                <Button className="" variant="primary">
                  Apply
                </Button>
              </div>
            </TextCard>
            <TextCard title="Order Total">
              <div className="flex justify-between my-4">
                <p className="text-[#585866]">Sub total</p>
                <p className="text-[#585866]">
                  <span>&#8358;</span>
                  {calculateCartTotal(cartData)}
                </p>
              </div>
              <div className="flex justify-between my-4">
                <p className="text-[#585866]">Delivery Fee</p>
                <p className="text-[#585866]">
                  <span>&#8358;</span>
                  {deliveryCost.toFixed(2)}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-4">
                <p className="font-semibold">Total</p>
                <p className="font-semibold">
                  {formatCurrency(Number(totalPrice))}
                </p>
              </div>
            </TextCard>
            <div className="hidden lg:block">
              <Payment
                onClose={() => setShowPayment(false)}
                email={user.email}
                amount={totalPrice}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <div className="lg:w-[80%] w-full lg:mt-12">
          <TextCard title="Delivery Details">
            <div className="p-4">
              <div className="w-full flex mt-6 justify-between ">
                <div className="gap-x-2 flex">
                  <p className="text-[#585866]">
                    <MapPin />
                  </p>
                  <p>Enter delivery location</p>
                </div>
                <Button size="sm" className="" variant="light">
                  Edit
                </Button>
              </div>
              <hr className="my-4" />
              <div className="w-full flex mt-6 justify-between ">
                <div className="gap-x-2 flex">
                  <p className="text-[#585866]">
                    <NotebookPen />
                  </p>
                  <p>Delivery note</p>
                </div>
                <Button size="sm" className="" variant="light">
                  Add note
                </Button>
              </div>
            </div>
          </TextCard>
        </div>
        <div className="lg:w-[80%] w-full">
          <TextCard title="Payment Method">
            <div className="p-4">
              <div className="w-full flex mt-6 justify-between ">
                <div className="gap-x-2 flex w-full">
                  <RadioGroup defaultValue="comfortable" className="w-full">
                    <div className="flex items-center space-x-2 gap-x-3">
                      <RadioGroupItem value="comfortable" id="r1" />
                      <p className="">
                        Pay with Cards, Bank Transfer or USSD <br />{" "}
                        <span className="text-xs text-[#81818F]">
                          You will be directed to a secure check out page
                        </span>
                      </p>
                    </div>
                    <hr className="my-4" />
                    <div className="flex  justify-between">
                      <div className="flex space-x-2 items-center gap-x-3">
                        <RadioGroupItem value="default" id="r2" />
                        <p className="">
                          Wallet (₦500.00) <br />{" "}
                          <span className="text-xs text-[#81818F]">
                            You will be directed to a secure check out page
                          </span>
                        </p>
                      </div>
                      <Button size="sm" className="" variant="light">
                        Edit
                      </Button>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </TextCard>
        </div>
        <div className="lg:hidden">
          <Payment
            onClose={() => setShowPayment(false)}
            email={user.email}
            amount={totalPrice}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
