import { useMutation } from "@tanstack/react-query";
import { createOrder } from "src/utils/api";

export interface IOrderProps {
    userId: string;
    orderItems: {
      quantity: number;
      productId: string;
    }[];
  }
export const useCreateOrderMutation = () => {
    return useMutation({
      mutationFn: (data: IOrderProps) => createOrder(data), 
    });
};

