import React from 'react'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useCreateOrderMutation } from 'src/services/orders';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generateTransactionRef } from 'src/utils/transactionUtils';
import { Button } from '../ui/button';

interface PaymentProps {
    onClose: () => void;
    amount: number;
    email: string;
  }

const Payment: React.FC<PaymentProps> = ({
    amount,
    email
}) => {
    const mutation = useCreateOrderMutation();
    const { user } = useSelector((state: any) => state.user);


    const handleOnRedirect = (data: any) => {
        if (data.status === "successful") {
          mutation.mutate(
            { userId: user?.userId, orderItems: [] },
            {
              onSuccess: () => {
                toast.success('Product added to cart successfully!', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                  closePaymentModal(); 
              },
            }
          );
        } else {
            toast.error('Please login to add items to cart', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
        }
    };

    const flutterwaveConfig = {
        tx_ref: generateTransactionRef(10),
        public_key: "FLWPUBK-fb05ef32ce304e6cd16a3aecb16846d4-X",
        customer: {
          email: email,
          phone_number: `${user.phone}`,
          name: `${user?.firstname} ${user?.lastname}`,
        },
        amount: amount,
        currency: "NGN",
        payment_options: "card",
        customizations: {
            title: "My Payment Title",
            description: "Payment for items in cart",
            logo: "",
          },
    };

  return (
    <>
      <Button size="lg" className="w-full my-2" variant="primary">
         <FlutterWaveButton
           callback={handleOnRedirect}
           text="Make Payment"
           onClose={() => console.log("Payment modal closed")}
           {...flutterwaveConfig} 
         />
        </Button>
  </>
  )
}

export default Payment
